import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/intro"
import Footer from "../components/footer"

const Privacy = ({ data: { page } }) => (
  <Layout>
    <SEO title={page.title} lang="it" />
    <Header>
      <h1>{page.title}</h1>
      <h2>{page.subtitle}</h2>
    </Header>
    <Intro title={page.title} text={page.intro} />
    <Footer />
  </Layout>
)

export default Privacy

export const query = graphql`
  query PrivacyQuery {
    page: datoCmsPrivacyPolicyPage {
      title
      subtitle
      intro
      slug
    }
  }
`
